<template>
  <div class="addDiscount container">
    <el-form
      :model="form"
      style="min-width: 1000px"
      label-width="150px"
      label-position="left"
      :rules="rules"
      ref="ruleForm"
    >
      <el-form-item label="商品金额" prop="goods_price">
        <el-input-number
          v-model="form.goods_price"
          step-strictly
          :step="0.01"
          :min="0.01"
          :max="1000000000000"
          :controls="false"
          placeholder="请输入数字"
        ></el-input-number>
        <span>元</span>
        <span class="gray" style="margin-left: 10px"
          >购买商品达到该金额且订单已完成时，自动发放该优惠券，触发多个规则时，发放顺序按规则列表优先级排序</span
        >
      </el-form-item>
      <el-form-item label="自动发放张数" prop="num">
        <el-input-number
          v-model="form.num"
          step-strictly
          :step="1"
          :min="1"
          :max="9"
          :controls="false"
          placeholder="请输入数字"
        ></el-input-number>
        <span>张</span>
        <span class="gray" style="margin-left: 10px"
          >每种优惠券一次发放的张数，优惠券总数发完时，购买将不再发放</span
        >
      </el-form-item>
      <el-button @click="addDiscountShow = true" type="primary"
        >选择优惠券</el-button
      >
      <div class="table-box">
        <el-table
          ref="multipleTable"
          :data="coupon_data_list"
          tooltip-effect="dark"
          style="width: 100%; min-width: 1000px"
          :header-cell-style="{ background: '#F5F5F5' }"
        >
          <el-table-column label="优惠券名称" width="300" prop="coupon_name">
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="create_time"
          ></el-table-column>
          <el-table-column label="使用条件">
            <template slot-scope="scope">
              满{{ scope.row.money }}减{{ scope.row.enough }}元
            </template>
          </el-table-column>

          <el-table-column
            prop="give_total"
            label="已发放数量"
          ></el-table-column>
          <el-table-column
            prop="off_total"
            label="已使用数量"
          ></el-table-column>
          <el-table-column prop="total" label="剩余发放数"></el-table-column>
          <el-table-column label="领券中心">
            <template slot-scope="scope">
              <span v-if="scope.row.coupon_centre == 1">上架中</span>
              <span v-if="scope.row.coupon_centre == 0">下架中</span>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              {{ scope.row.is_overdue }}
            </template>
          </el-table-column>

          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="medium"
                @click="delDiscount(scope.row.coupon_data_id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-form-item label="开启赠送商品条件">
        <el-radio v-model="form.goods_limit" label="0">否</el-radio>
        <el-radio v-model="form.goods_limit" label="1">是</el-radio>
        <span class="gray" style="margin-left: 10px"
          >选否，则店铺所有商品购买达到金额即发放；选是，则仅购买以下所选商品达到金额即发放</span
        >
      </el-form-item>
      <el-button @click="addGoodsShow = true" type="primary"
        >选择商品</el-button
      >
      <div class="table-box">
        <el-table
          ref="multipleTable"
          :data="goods_list"
          tooltip-effect="dark"
          style="width: 100%; min-width: 1000px"
          :header-cell-style="{ background: '#F5F5F5' }"
        >
          <el-table-column label="商品" width="300">
            <template slot-scope="scope">
              <div class="flex align-center">
                <img
                  :src="scope.row.picture_str || scope.row.picture"
                  alt
                  style="width: auto; height: 76px; margin-right: 5px"
                />
                <p>{{ scope.row.goods_name }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格">
            <template slot-scope="scope">
              {{ scope.row.sku_name || "" }}
            </template>
          </el-table-column>
          <el-table-column prop="price" label="价格"></el-table-column>
          <el-table-column prop="member_price" label="会员价"></el-table-column>
          <el-table-column prop="stock" label="库存"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="medium"
                class="red"
                @click="deleteGoods(scope.$index)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="submit flex center align-center">
        <el-button type="info" @click="cancel" size="medium">取消</el-button>
        <el-button type="primary" @click="submit" size="medium">提交</el-button>
      </div>
    </el-form>
    <el-dialog title="选择优惠券" :visible.sync="addDiscountShow" width="600px">
      <div class="content">
        <p class="align-center flex">
          <el-input
            v-model="coupon_keyword"
            placeholder="优惠券关键字"
          ></el-input>
          <el-button type="primary" @click="getCounponLists">查询</el-button>
        </p>
        <div class="box">
          <p
            class="item flex align-center"
            v-for="item in discountList"
            :key="item.coupon_data_id"
          >
            <el-checkbox
              v-model="item.checked"
              style="margin-right: 10px"
            ></el-checkbox
            ><span>{{ item.coupon_name }}</span>
            <span>满{{ item.money }}减{{ item.enough }}</span>
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDiscountShow = false">取 消</el-button>
        <el-button type="primary" @click="chooseDiscount">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择商品" :visible.sync="addGoodsShow" width="600px">
      <div class="content">
        <p class="align-center flex">
          <el-input v-model="goods_name" placeholder="商品关键字"></el-input>
          <el-button type="primary" @click="getGoodsLists">查询</el-button>
        </p>
        <div class="box">
          <p
            class="item flex align-center"
            v-for="item in goodsSpuList"
            :key="item.goods_id"
          >
            <el-checkbox
              v-model="item.checked"
              style="margin-right: 10px"
            ></el-checkbox>
            <img :src="item.picture_str" alt="" />
            <span>{{ item.goods_name }}</span>
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGoodsShow = false">取 消</el-button>
        <el-button type="primary" @click="chooseGoods">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "addRule",
  data() {
    return {
      rule_id: this.$route.query.rule_id,
      addDiscountShow: false,
      addGoodsShow: false,
      coupon_keyword: "",
      goods_name: "",
      discountList: [],
      coupon_data_list: [],
      goodsSpuList: [],
      goods_list: [],
      form: {
        goods_price: undefined,
        num: undefined,
        goods_limit: "0",
      },
      rules: {
        goods_price: [{ required: true, message: "商品金额", trigger: "blur" }],
        num: [
          { required: true, message: "请输入自动发放张数", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getInfo();
    this.getCounponLists();
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    getInfo() {
      let data = {
        rule_id: this.rule_id,
      };
      this.axios
        .post("/store/Shopcoupon/shopGiveCouponInfo", data)
        .then((res) => {
          let info = res.data.info;
          console.log(info);
          this.form = {
            goods_price: info.goods_price,
            num: info.num,
            goods_limit: info.goods_limit.toString(),
          };
          info.coupon_list.forEach((ele) => {
            ele.create_time = this.dayjs
              .unix(ele.create_time)
              .format("YYYY-MM-DD HH:mm");
             ele.is_overdue = ele.is_overdue == 1 ? "已过期" : "未过期";
          });
          this.coupon_data_list = info.coupon_list;
          this.goods_list = info.goods_list;
        });
    },
    //提交表单
    submit() {
      if (this.loading) return;
      let check = false;
      this.$refs.ruleForm.validate((valid) => {
        check = valid;
      });
      if (!check) {
        return;
      }
      if (this.coupon_data_list.length == 0) {
        this.$message.error("必须指定参加活动的优惠券");
        return;
      }
      if (this.form.goods_limit == 1 && this.goods_list.length == 0) {
        this.$message.error("必须指定参加活动的商品");
        return;
      }
      this.loading = true;
      let data = {
        goods_price: this.form.goods_price,
        num: this.form.num,
        goods_limit: this.form.goods_limit,
        rule_id:this.rule_id
      };
      let coupon_data_id = this.coupon_data_list.map((ele) => {
        return ele.coupon_data_id;
      });
      data.coupon_data_id = coupon_data_id.toString();
      if (this.form.goods_limit == 1) {
        data.goods_list = this.goods_list.map((ele) => {
          return {
            goods_id: ele.goods_id,
            sku_id: ele.sku_id || 0,
          };
        });
      }
      console.log(data)
      this.axios
        .post("/store/Shopcoupon/updateShopGiveCoupon", data)
        .then(() => {
          this.$message.success("编辑成功");
          this.loading = false;
          this.$router.push("/operation/ruleList");
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getCounponLists() {
      let data = {
        coupon_keyword: this.coupon_keyword,
        pageNumber: 1000,
      };
      this.axios.post("/store/Shopcoupon/counponLists", data).then((res) => {
        let list = res.data.list;
        list.forEach((ele) => {
          ele.checked = false;
          ele.create_time = this.dayjs
            .unix(ele.create_time)
            .format("YYYY-MM-DD HH:mm");
        });
        this.discountList = list;
      });
    },
    chooseDiscount() {
      let arr = [];
      this.discountList.forEach((ele) => {
        if (ele.checked) {
          arr.push(ele);
        }
      });
      if (arr.length == 0) {
        this.$message.error("请选择优惠券");
        return;
      }
      arr.forEach((ele) => {
        let index = this.coupon_data_list.findIndex((ele2) => {
          return ele.coupon_data_id == ele2.coupon_data_id;
        });
        if ((index = -1)) {
          this.coupon_data_list.push(ele);
        }
      });
      this.addDiscountShow = false;
      this.coupon_keyword = "";
      this.discountList = [];
    },
    delDiscount(coupon_data_id) {
      this.$confirm("确认删除优惠券吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let index = this.coupon_data_list.findIndex((ele2) => {
          return coupon_data_id == ele2.coupon_data_id;
        });
        this.coupon_data_list.splice(index, 1);
      });
    },
    getGoodsLists() {
      let data = {
        goods_name: this.goods_name,
        pageNumber: 1000,
      };
      this.axios.post("/store/Shopcoupon/ShopGoodsLists", data).then((res) => {
        let list = res.data.list;
        list.forEach((ele) => {
          ele.checked = false;
        });
        this.goodsSpuList = list;
      });
    },
    chooseGoods() {
      let arr = [];
      this.goodsSpuList.forEach((ele) => {
        if (ele.checked && ele.open_spec == 1) {
          arr = arr.concat(ele.item);
        } else if (ele.checked && ele.open_spec == 0) {
          arr.push(ele);
        }
      });
      if (arr.length == 0) {
        this.$message.error("请选择商品");
        return;
      }
      arr.forEach((ele) => {
        let index = this.goods_list.findIndex((ele2) => {
          if (ele.goods_id) {
            return ele.goods_id == ele2.goods_id;
          } else if (ele.sku_id) {
            return ele.sku_id == ele2.sku_id;
          }
        });
        if ((index = -1)) {
          this.goods_list.push(ele);
        }
      });
      this.$message.success("添加成功");
      this.goods_name = "";
      this.goodsSpuList = [];
    },
    deleteGoods(index) {
      this.goods_list.splice(index, 1);
    },
  },
};
</script>

<style lang='scss' scoped>
.el-input,
.el-input-number,
.el-cascader {
  width: 250px;
  margin-right: 5px;
}

.submit {
  margin: 30px 0;
}
.el-dialog {
  .box {
    height: 300px;
    overflow-y: auto;
    p {
      line-height: 30px;
      margin: 10px 0;
      span {
        margin-right: 15px;
      }
      img {
        width: 40px;
        margin-right: 10px;
      }
    }
  }
}
</style>

